import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Metasearch travel engine" link="http://www.marmarit.nl/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Marmarit Agency Web Application & Web Services
    </ProjectCard>
    <ProjectCard title="Ebooking Web Application" link="http://ebooking.iranair.com/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  IranAir ebooking project
    </ProjectCard>
    <ProjectCard title="CRM Web Application" link="#" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  A solution for Customer Relationship Management and the custome API.
    </ProjectCard>
    <ProjectCard title="Air Cargo Application" link="#" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Cargo Management Desktop App & Website Design
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      