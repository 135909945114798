import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I am a Full Stack Developer with familiarity in each layer, if not mastery in many and a genuine interest in all Software Technology and working Globaly with Corporations, Startups and Agencies. `}</p>
    </blockquote>
    <p>{`I design & develop compelling apps and engaging brands. All helping to improve response, boost profitability and drive sales. I am integrating into my clients’ teams, becoming their contractor of choice. I’ll fit seamlessly into your workflow. Whether that’s taking client feedback directly, or attending client meetings as ‘one of your agency team’, we never disclose who I am or what I do for your business.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      